import React from 'react'
import RecentOrdersPage from '../components/user/RecentOrdersPage'

const OrdersAreaPage = () => {
  return (
      <div className="container">
        <div className="row">
          <div className="col-md">
            <RecentOrdersPage />
          </div>
        </div>
      </div>
  )
}

export default OrdersAreaPage

import { useState, useEffect } from 'react'
import { fetchPaymentOrderData } from './paymentorderdata_api'

export const usePaymentOrderData = (options: any) => {
  const [paymentOrderData, setPaymentOrderData] = useState(undefined)

  useEffect(() => {
    fetchPaymentOrderData(options).then(response => {
      setPaymentOrderData(response.data)
    })
  }, [options.orderId + '-' + options.mailAddress])

  return paymentOrderData
}
